.dropdown-menu-media,
.dropdown-user-menu {
    border: 1px solid #fff;
    border-top: 3px solid $brand-info;
    padding: 0;
    h5 {
        font-weight: 300;
    }
    > .panel-default {
        width: 400px;
        margin: 0;
        border: none;
        > .panel-heading {
            background: #fff;
            border: 0;
        }
        > .panel-footer {
            background: #f5f5f5;
            padding: 20px;
            a {
                color: $gray-light;
                &:hover {
                    text-decoration: none;
                    color: $brand-info;
                }
            }
        }
    }
    .scrollable {
        max-height: 250px;
        overflow-y: hidden;
        &:hover { overflow-y: scroll; }
    }
    .list-group-item {
        padding: 0;
        border: none;
    }
    .list-group-item  {
        .list-group-item-a {
            display: block;
            border: none;
            padding: 0 20px;
            color: #666;
            &:hover {
                text-decoration: none;
            }
        }
        &:hover {
            background-color: #f1f1f1;
        }
        .media-close {
            display: none;
            position: absolute;
            top: 30px;
            right: 20px;
            color: #ccc;
            &:hover {
                color: $brand-info;
            }
        }
        &:hover .media-close {
            display: block;
        }
    }
    .media {
        padding: 20px 0;
        border-top: 1px solid #f1f1f1;
    }
}

.dropdown-user-menu > .panel-default {
    width: 200px;
}

@media (min-width: 768px) {
    .navbar.navbar-default {
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 0;
    }
    .navbar-nav > li > a,
    .navbar-brand {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}