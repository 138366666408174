#login {
    background: url('../images/login-cover.jpg') no-repeat 50% 50% fixed;
    width: 100vw;
    height: 100vh;

    .password-reset a {
        color: #fff;
        font-size: 16px;
        font-weight: 300;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        margin-top: 10px;
        display: block;
        background: rgba(0,0,0,0.3);
        padding: 5px 5px;
        border-radius: 3px;
        text-align: right;
    }
}