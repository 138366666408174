.Chartbox {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 0;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 29px;
    &--colored {
        color: #fff;
        small {
            color: #fff;
        }
    }
}
.Chartbox__header {
    padding: 20px;
}
.Chartbox__title {
    font-weight: 100;
    margin-top: 0;
}
.Chartbox__number {
    float: right;
    font-size: 1.3em;
    font-weight: 400;
}
.Chartbox__subtitle {
    font-weight: 300;
    color: #888;
    .Chartbox--colored & {
        color: #fff;
    }
}
