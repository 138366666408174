#chart-phishings {
    .ct-bar {
        stroke-width: 40px;
    }
}
.chartist-tooltip {
    position: absolute;
    display: inline-block;
    min-width: 5em;
    padding: .5em;
    background: #F4C63D;
    color: #453D3F;
    font-weight: 700;
    text-align: center;
    pointer-events: none;
    z-index: 1;
}

$chart-tooltip-bg: #F4C63D;
$chart-tooltip-color: #453D3F;
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: .5em;
  background: $chart-tooltip-bg;
  color: $chart-tooltip-color;
  font-family: Oxygen,Helvetica,Arial,sans-serif;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top-color: $chart-tooltip-bg;
  }
  &.tooltip-show {
    opacity: 1;
  }
}