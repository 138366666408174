.SimpleList {
    margin-bottom: 20px;
}

.SimpleList__item {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    p, ul > li {
        color: #666;
    }
    &:last-child {
        border-bottom: none;
    }
    ul {
        margin-top: 20px;
    }
}