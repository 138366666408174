/*.UserList__item {
    display: flex;
    padding: 10px 0 5px 0;
    border-bottom: 1px solid #ddd;
    &:hover {
        background: #fff;
    }
}
.UserList__picture {
    flex-basis: 80px;
    figure {
        margin: 0 10px;
        max-width: 60px;
    }
}
.UserList__name {
    font-size: 20px;
    font-weight: 300;
    flex: 3;
}
.UserList__meta {
    color: #999;
}
.UserList__content {
    flex: 3;
    margin-right: 20px;
}
.UserList__roles {
    flex: 2;
    padding-top: 12px;
}
.UserList__actions {
    flex: 1;
    padding-top: 10px;
    text-align: right;
    padding: 20px;
}
*/
.UserList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.UserList__item {
  position: relative;
  width: 250px;
  text-align: center;
  background: #fff;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  padding: 20px;
}
.UserList__name {
  font-weight: 100;
  font-size: 25px;
  color: #333;
  margin: 5px 0;
}
.UserList__name small { display: none; }
.UserList__picture img {
  margin: 5px 0;
  border-radius: 100px;
  border: 8px solid #fff;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
}
.UserList__meta {
  color: #999;
  font-weight: 300;
}
.UserList__roles {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.label-danger {
  background: tomato;
  color: #fff;
  padding: 3px 5px;
  font-size: 12px;
  margin: 3px;
  border-radius: 3px;
}
.UserList__actions {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: space-between;
  a {
    display: block;
    color: #ccc;
    margin: 0 3px;
    &:hover {
      color: #999;
    }
  }
}