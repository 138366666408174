// make grid without dashes and more clean
$ct-grid-color: rgba(0, 0, 0, 0.1);
$ct-grid-dasharray: 0;
// font bigger
$ct-text-size: 13px;
$ct-area-opacity: 0.5;



$ct-series-colors: (
  #62a8ea,
  #926dde,
  #f4c63d,
  #d17905,
  #453d3f,
  #59922b,
  #0544d3,
  #6b0392,
  #f05b4f,
  #dda458,
  #eacf7d,
  #86797d,
  #b2c326,
  #6188e2,
  #a748ca
);