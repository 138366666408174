.Divider {
    text-align: center;
    font-weight: 900;
    color: #333;
    position: relative;
    margin: 20px 10px;
    text-transform: uppercase;
    &:before, &:after {
        position: absolute;
        width: 40%;
        border-bottom: 1px solid #ccc;
        content: "";
        top: 50%;
    }
    &:before {
        left: 0;
    }
    &:after {
        right: 0;
    }
}
.Divider--15 {
    &:before, &:after {
      width: 35%;
    }
}
.Divider--20 {
    &:before, &:after {
      width: 30%;
    }
}