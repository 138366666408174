$off-canvas-width: 260px;
$off-canvas-color: rgb(38, 50, 56);
/**
 * Sidebar position and basic style
 */
/* #wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%; // removed to test what is happening
} */
#wrapper {
    // width: 100%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    transition: .3s ease all;
}
#sidebar {
    width: $off-canvas-width;
    height: 100%;
    position: fixed;
    background: $off-canvas-color;
    margin-left: -$off-canvas-width;
    transition: .3s ease all;
    z-index: 10000;
}
#wrapper.show-nav{
    //transform: translateX($off-canvas-width);
    margin-left: $off-canvas-width / 1.5;
}
#sidebar.show-nav {
    margin-left: 0;
}
/* #wrapper.show-nav #canvas-overlay {
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: $off-canvas-width;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.9);
    transition: .3s ease all;
} */
.toggle-sidebar {
    i.fa-bars {
        transform: rotate(-180deg);
        transition: transform .4s ease-in-out;
    }
}

/* Menu Styling */
#sidebar > div.list-group {
    .list-group-item {
        transition: .2s ease all;
        border: 0;
        font-weight: 300;
        &:first-child, &:last-child {
            border-radius: 0;
        }
        .fa-angle-right {
            transition: .2s ease all;
            transform: rotate(-90deg);
        }
        &.collapsed {
            .fa-angle-right {
                transform: rotate(90deg);
            }
            &:hover {
                background-color: lighten($off-canvas-color, 5%);
                transition: .2s ease all;
            }
        }
    }
    > .list-group-item {
        background-color: darken($off-canvas-color, 2%);
        &:hover {
            background-color: darken($off-canvas-color, 2%);
        }
    }
    > .list-group-item.collapsed {
        background: $off-canvas-color;
    }
    .list-group-item-heading {
        color: lighten($off-canvas-color, 70%);
        font-weight: 300;
        .fa-angle-right {
            transition: .3s ease all;
        }
    }
    h4 {
        font-size: 16px;
    }
}
.sub.list-group {
    margin-bottom: 0;
    > .list-group-item {
        background-color: darken($off-canvas-color, 2%);
        padding-left: 60px;
        border-bottom: 0;
        &:hover {
            background-color: $off-canvas-color;
        }
        .list-group-item-heading {
            font-size: 13px;
        }
    }
}
#sidebar .fa-fw {
    color: lighten($off-canvas-color, 30%);
}