.Box {
    position: relative;
    padding: 0;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 29px;
    &--shadow {
        box-shadow: 1px 1px 1px rgba(0,0,0,0.1)
    }
}
.Box__header {
    padding: 20px;
}
.Box__list {
    list-style: none;
    padding-left: 10px;
    color: #888;
    .Box--chart & {
        .fa {
            font-size: 11px;
            margin-right: 10px;
        }
    }
}
.Box__list-item {
    margin-bottom: 8px;
    &--inline {
        float: left;
        margin-right: 20px;
    }
}
.Box__title {
    font-weight: 100;
    margin-top: 0;
}
.Box__subtitle {
    font-weight: 300;
    color: #888;
}
.Box__content {
    padding: 20px;
    .Box--chart & {
        padding: 0;
    }
}
.Box--chart__legend {
    border-right: 1px dashed #ccc;
    &:first-child {
        border-right: none;
    }
}