.Activity__day {
    font-size: 12px;
    float: left;
    width: 80px;
    text-align: center;
    background: #fff;
    border-radius: 100px;
    color: #444;
    margin-top: 14px;
    font-weight: bold;
    padding: 10px;
    position: relative;
    border: 1px solid #e1e1e1;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    text-transform: uppercase;
    font-weight: 300;
    &:before {
        position: absolute;
        right: -50px;
        top: 8px;
        border-bottom: 1px solid #e1e1e1;
        width: 50px;
        content: "";
        height: 10px;
    }
}
.Activity__item {
    margin-left: 130px;
    color: #444;
    display: block;
    padding: 10px;
    background: #fff;
    /* border: 1px solid #e1e1e1; */
    margin-bottom: 3px;
    position: relative;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    border-radius: 3px;
    &:before {
        position: absolute;
        left: -91px;
        top: 52px;
        border-left: 1px solid #e1e1e1;
        width: 1px;
        content: "";
        height: 85px;
    }
}

.Activity__header {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #333;
}

.Activity__meta {
    color: #777;
    font-size: 12px;
}
.Activity__ip {
    font-weight: bold;
    float: right;
}
