.DecisionBox {
    background: #fff;
    padding: 20px;
}
.DecisionBox__question {
    margin-bottom: 20px;
}
.DecisionBox__title {
    margin: 0;
    font-weight: 100;
    text-align: center;
}
.DecisionBox__options {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}
.DecisionBox__option {
    padding: 30px 60px;
    background: #fff;
    color: #666;
    margin: 10px;
    font-size: 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    border: 1px solid #f1f1f1;
    &:hover {
        text-decoration: none;
        background: #f1f1f1;
        color: #333;
    }
}
.DecisionBox__option--success {
    background: $brand-success;
    border: none;
    color: #fff;
    &:hover {
        background: darken($brand-success, 5%);
        color: #fff;
    }
}