.Linetab {
    margin-bottom: 40px;
    padding-left: 0;
}
.Linetab__list {
    padding-left: 0;
    border-bottom: 1px solid #ddd;
}
.Linetab__list-item {
    display: inline-block;
    margin-right: 1em;
    font-size: 17px;
    font-weight: 700;
    color: #aaa;
}
.Linetab__list-item-link {
    display: block;
    color: #999;
    padding: 0 5px 20px 5px;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    &:hover,
    .Linetab__list-item--active & {
        color: $brand-primary;
        border-bottom: 3px solid $brand-primary;
        text-decoration: none;
    }
}