$divided-panel-primary-color: #F5F5F5;
$divided-panel-secondary-color: #3c5373;
$panel-background: #FF3232;

.custom-panel-two-colors {
    height: 80px;
    border-left: $divided-panel-secondary-color 2px solid;
    vertical-align: middle;
    background: $panel-background;
    background: -moz-linear-gradient(left, $divided-panel-primary-color 0%);
    background: -webkit-linear-gradient(left, $divided-panel-primary-color 0%, $divided-panel-primary-color 83%, $divided-panel-secondary-color 7%, $divided-panel-secondary-color 7%);
}