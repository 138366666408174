body {
    background: $base-layout-background-color;
}
.navbar.navbar-default {
    margin-bottom: 0;
}
#featured {
    //padding: 20px;
    background: transparent url('../images/header-bg.jpg');
    //height: 80px;
    /*h1 {
        margin: 0;
        color: #fff;
        font-weight: 100;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    */
}
.featured-title {
    color: #fff;
    font-weight: 300;
    margin-top: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.featured-subtitle {
    color: #eee;
}
#featured-search-input {
    border: none;
    border-radius: 0;
    height: 45px; // 1 pixel minus to match with button
    background-color: rgba(255,255,255, 0.5);
    color: #012D1B;
    &::-webkit-input-placeholder {color: #fff;}
    &:-moz-placeholder {color: #fff;}
    &::-moz-placeholder {color: #fff;}
    &:-ms-input-placeholder {color: #fff;}
}
#main-content {
    padding: 20px;
}

@media (max-width: 768px) {
    .feed_url {
      font-size: 13px;
    }
}

@media(min-width: 769px) and (max-width: 992px) {
  .feed_url {
    font-size: 16px;
  }
}