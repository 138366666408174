/* applied a smooth hover on and off effect on buttons */
.btn, .btn:hover, .btn:active, .btn:focus {
    transition: border .2s linear,
                color .2s linear,
                width .2s linear,
                background-color .2s linear;
}
.fa.spaced {
    padding-right: 40px;
}
.fa.has-badges {
    position: relative;
}
.fa.has-badges.up {
    .badge {
        position: absolute;
        padding: 3px 4px;
    }
    &.up .badge {
        top: -10px;
        left: 8px;
    }
}
.badge-primary {background-color: $brand-primary; }
.badge-green {background-color: $brand-success; }
.badge-info {background-color: $brand-info; }
.badge-danger {background-color: $brand-danger; }

.fa-blue { color: $brand-primary; }
.fa-green { color: $brand-success; }
.fa-cyan { color: $brand-info; }
.fa-red { color: $brand-danger; }

.bordered-shadow { box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); }
.label-circle { border-radius: 4em; }

.NRModal {
    .modal-content {
        border-radius: 3px;
        box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
        padding: 0 20px;
    }
    .modal-title {
        font-size: 25px !important;
        font-weight: 300;
        color: #333;
    }
}

.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    border-radius: 2px;
}

.btn.btn-cancel {
    background: #fff;
    border: none;
    font-weight: 700 !important;
    color: $brand-danger;
    text-transform: uppercase;
}
.upp {
    text-transform: uppercase;
}
.table.table-large-row td,
.table.table-large-row th {
    padding: 18px;
    color: #666;
}
.table.table-large-row th {
    color: rebeccapurple;
    font-weight: 300;
}
.table.table-large-row .version {
    font-weight: 600;
}
.table .clickable-row {
    cursor: pointer;
}
.table th.fixed20 {
    width: 20%;
}

.dropdown-menu.dropdown-scroll {
    max-height: 400px;
    overflow-y: scroll;
}