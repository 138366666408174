.Chart {
    position: relative;
}
.Chart__donut-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
}

/** helper classes */
.BarLarge30 .ct-chart-bar .ct-bar {
    stroke-width: 30px;
}