.RList {
  margin: 0;
  padding: 0;
}
.RList__item {
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.RList__item:hover {
  background: #fcfcfc;
}
.RList__item:first-child {
  border-top: 1px solid #eee;
}
.RList__item > div {
  width: 100px;
  flex-grow: 1;
  padding: 30px 20px;
  color: #666;
  display: flex;
  align-items: center;
  font-weight: 300;
}
.RList .RList__filename {
  flex-grow: 4;
  font-weight: 900;
}