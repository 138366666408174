.FavoriteButton {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: transparent;
    border: none;
}
.FavoriteButton:focus {
    outline: none;
}
.FavoriteButton:hover,
.FavoriteButton.active {
    color: #FFA500;
}