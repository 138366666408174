.user-timeline {
    article, .ticket-replies {
        padding-bottom: 40px;
        position: relative;
    }
    article:before, .ticket-replies:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        border-left: 1px solid #ddd;
        left: 35px;
    }
    .user-content {
        padding: 0;
        background: #fff;
        position: relative;
        border-radius: 3px;
        border: 1px solid #ccc;
        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            top: 25px;
            left: -6px;
            width: 11px;
            height: 11px;
            background: #fff;
            border-left: 1px solid #ccc;
            border-top: 1px solid #ccc;
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        .main-content {
            padding: 10px 20px 20px 20px;
        }
    }
    article.reply,
    .reply-form {
        .user-picture {
            padding: 0 20px 0 20px;
        }
    }
    .meta {
        color: #999;
    }
    .content {
        margin-top: 15px;
        line-height: 1.8em;
        font-size: 15px;
        color: #666;
    }
    .user-attachments .attachments {
        padding: 10px;
        background: #3C4F6F;
        margin-bottom: 0;
        a {
            margin: 2px 0;
        }
    }
}
.user-timeline {
    .ticket-replies {
        font-weight: 700;
        color: #9A9A9A;
        span {
            background: #fff;
            padding: 10px 20px;
            border-radius: 30px;
            border: 1px solid #ddd;
            position: relative;
        }
        &:after {
            border-top: 1px solid #ddd;
            display: block;
            width: 100%;
            height: 10px;
            content: " ";
            position: relative;
            z-index: -1;
            top: -12px;
        }
    }
}
#ticket-nav {
    .user-picture img {
        width: 70px;
        height: 70px;
        border-radius: 50px;
        border: 3px solid #ccc;
    }
}

// ticket creation specifics
#ticket-create {
    .checkboxes {
        margin-bottom: 20px;
    }
}
.reply-form {
    textarea {
        width: 100%;
        border: none;
        &:focus {
            outline-color: transparent;
        }
    }
    .reply-form-submit {
        margin-top: 20px;
    }
    .checkboxes {
        // make the statuses checkboxes hided
        display: none;
    }
}
.dropzone {
    border: 1px dashed rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    &:hover {
        border-color: $brand-info;
    }
}
#featured {
    .ticket-info-avatar {
        position: relative;
        z-index: 2;
        overflow: visible;
        text-align: left;
        font-size: 14px;
        padding-left: 0;
        float: left;
        margin-right: 20px;
        img {
            width: 64px;
        }
    }
    .ticket-info-heading {
        color: #fff;
        font-weight: 300;
        margin-top: 0;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    .ticket-info-sub-heading {
        font-size: 17px;
        font-weight: 400;
        color: #fff;
    }
}
.ticket-preview,
.ticket-preview .body {
    overflow: hidden;
}

.ticket-preview {
    margin-bottom: 20px;
    .avatar {
        position: relative;
        z-index: 2;
        overflow: visible;
        text-align: left;
        font-size: 14px;
        padding-left: 0;
        float: left;
        margin-right: 20px;
        min-width: 100px;
        text-align: center;
        img {
            width: 64px;
        }
    }
    .body {
        float: left;
        margin-right: 40px;
    }
    h2.heading {
        font-size: 25px;
        font-weight: 300;
        margin-bottom: 3px;
        margin-top: 0;
        a {
            color: #3B305B;
        }
    }
    p.sub-heading {
        color: #999;
        font-size: 17px;
    }
    .meta {
        color: #999;
        span {
            color: #3C5373;
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            display: block;
        }
    }
}
