.GlobalSearch,
.GlobalSearch input {
    width: 100%;
    background: #f1f1f1;
}
.GlobalSearch { display: none }
.GlobalSearch input {
    border: 0;
    padding: 13px;
    font-size: 20px;
    &:focus {
        border: 0;
        outline: 0;
        box-shadow: none;
    }
}