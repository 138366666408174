/**
 * All variables declared here will override the variables declared on
 * node_modules/bootstrap-sass
*/

/* make the Roboto the default font */
$font-family-sans-serif:  Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;

/*
 * buttons
 * removed the button borders
 */
$btn-default-border:             #ccc !default;
$btn-primary-border:             transparent;
$btn-success-border:             transparent;
$btn-info-border:                transparent;
$btn-warning-border:             transparent;
$btn-danger-border:              transparent;

/* 1px less radius */
$btn-border-radius-base:         2px;
$btn-border-radius-large:        2px;
$btn-border-radius-small:        2px;

/* Custom variables */
$base-layout-background-color: #f1f4f5;

/* base colors */
$brand-primary:         #62a8ea;
$brand-success:         #46be8a;
$brand-info:            #57c7d4;
$brand-warning:         #f2a654;
$brand-danger:          #F96868;

.color-primary {color: $brand-primary;}
.color-success {color: $brand-success;}
.color-info {color: $brand-info;}
.color-warning {color: $brand-warning;}
.color-danger {color: $brand-danger;}

.color-chart-0 {color: #62a8ea;}
.color-chart-1 {color: #926dde;}
.color-chart-2 {color: #f4c63d;}
.color-chart-3 {color: #d17905;}
.color-chart-4 {color: #453d3f;}
.color-chart-5 {color: #59922b;}
.color-chart-6 {color: #0544d3;}
.color-chart-7 {color: #6b0392;}
.color-chart-8 {color: #f05b4f;}
.color-chart-9 {color: #dda458;}
.color-chart-10 {color: #eacf7d;}
.color-chart-11 {color: #86797d;}
.color-chart-12 {color: #b2c326;}
.color-chart-13 {color: #6188e2;}
.color-chart-14 {color: #a748ca;}

/* old colors
$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;
*/


/** Modals */
$modal-title-padding: 20px;
$modal-content-border-color: transparent;
$modal-content-fallback-border-color: transparent;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;

/** Inputs */
$input-border-radius: 2px;
$input-border-radius-large: 2px;
$input-border-radius-small: 2px;
$input-border: #ddd;